import adagioLogo from "../assets/images/logo-adagio.jpg";
import carawayLogo from "../assets/images/logo_caraway.jpg";
import carismaLogo from "../assets/images/logo-carisma.jpg";
import entradaLogo from "../assets/images/logo_entrada.jpg";
import hotspotLogo from "../assets/images/logo_hotspot.jpg";
import imagoLogo from "../assets/images/logo-imago.jpg";
import lifemineLogo from "../assets/images/logo_lifemine.jpg";
import tallacLogo from "../assets/images/logo_tallac.jpg";
import xilioLogo from "../assets/images/logo_xilio.jpg";
import mozartLogo from "../assets/images/logo_mozart.jpg";
import alectorLogo from "../assets/images/logo_alector.jpg";
import kymeraLogo from "../assets/images/logo_kymera.jpg";
import lavaLogo from "../assets/images/logo-lava.jpg";
import paqLogo from "../assets/images/logo_paq.jpg";
import speroLogo from "../assets/images/logo_spero.jpg";
import theriniLogo from "../assets/images/logo-therini.jpg";
import tidalLogo from "../assets/images/logo_tidal.jpg";
import translateLogo from "../assets/images/logo_translate.jpg";
import viridianLogo from "../assets/images/logo_viridian.jpg";
import visterraLogo from "../assets/images/logo_visterra.jpg";
import eyebioLogo from "../assets/images/logo_eyebio.jpg";
import ambagonLogo from "../assets/images/logo_ambagon.jpg";
import AktisLogo from "../assets/images/logo_Aktis.jpg";
import photysLogo from "../assets/images/logo_photys.jpg";
import InduProLogo from "../assets/images/logo_induPro.jpg";
import ADRxLogo from "../assets/images/logo_ADRx.jpg";
import InvivydLogo from "../assets/images/Invivyd.jpg";
import AnavotxLogo from "../assets/images/anavotx-logo.jpg";
import RayTherapeuticsLogo from "../assets/images/raytherapeutics-logo.jpg";
import vectorYLogo from "../assets/images/logo-vectory.png";
import InsamoLogo from "../assets/images/logo-insamo.jpg";

const portfolio = {
  active: [
    {
      name: "Structure-based small-molecule drug design to treat protein aggregation diseases.",
      url: "https://www.adrxpharma.com/",
      img: ADRxLogo
    },
    {
      name: "Targeted alpha-emitting radionuclide therapeutics. ",
      url: "https://www.aktisoncology.com/",
      img: AktisLogo
    },
    {
      name: "Ambagon is developing small molecule drugs against disordered proteins by targeting the 14-3-3 family.",
      url: "https://www.ambagontx.com/",
      img: ambagonLogo
    },
    {
      name: "Systematically drugging the phosphatase target class with small molecule modulators.",
      url: "https://www.anavotx.com/",
      img: AnavotxLogo
    },
    {
      name: "Hotspot Therapeutics is using computational predictions to identify and modulate novel regulatory allosteric pockets.",
      url: "https://www.hotspotthera.com",
      img: hotspotLogo
    },
    {
      name: "Proximity-dependent logic-gated multivalent biologics.",
      url: "https://www.induprolabs.com/",
      img: InduProLogo
    },
    {
      name: "Discovering membrane-permeable and orally available cyclic-peptides.",
      url: "https://insamo.com/",
      img: InsamoLogo
    },
    {
      name: "LifeMine has developed a genomically-enabled drug discovery engine that leverages fungal biology to identify novel chemical matter that could address previously intractable therapeutic targets.",
      url: "http://lifeminetx.com/",
      img: lifemineLogo
    },
    {
      name: "Mozart is developing CD8 Treg cell modulators for the treatment of autoimmune and inflammatory diseases.",
      url: "https://www.mozart-tx.com/",
      img: mozartLogo
    },
    {
      name: "PAQ is developing novel autophagosome-tethering compounds (ATTECs) to catalyze and selectively enhance the degradation of disease-causing entities through autophagy.",
      url: "https://www.paqtx.com/",
      img: paqLogo
    },
    {
      name: "Photys Therapeutics is developing novel bifunctional small molecules for fine-tuned control of post-translational modifications beyond ubiquitination. ",
      url: "https://www.photys.com",
      img: photysLogo
    },
    {
      name: "Restoring vision through optogenetics.",
      url: "https://raytherapeutics.com/",
      img: RayTherapeuticsLogo
    },
    {
      name: "Tallac Therapeutics is developing immunomodulating polynucleotides.",
      url: "https://www.tallactherapeutics.com/",
      img: tallacLogo
    },
    {
      name: "Therini is dedicated to the research and development of novel therapeutics for the treatment of inflammatory diseases due to vascular dysfunction.",
      url: "http://www.therinibio.com/",
      img: theriniLogo
    },
    {
      name: "Vectorized antibodies for neurodegenerative diseases.",
      url: "https://www.vectorytx.com/",
      img: vectorYLogo
    }
  ],
  acquired: [
    {
      name: "Alector is developing therapies that harness the immune system to treat neurodegenerative disorders and cancer. (NASDAQ: ALEC)",
      url: "https://www.alector.com/",
      img: alectorLogo
    },
    {
      name: "Caraway is developing novel small molecules that modulate mitophagy and restore cellular imbalance in neurodegenerative diseases. (Acquired by Merck & Co., Inc., Rahway, NJ US) ",
      url: "https://carawaytx.com",
      img: carawayLogo
    },
    {
      name: "Carisma’s platform technology confers antigen-specific recognition to macrophages, creating novel cellular therapeutics (CAR-Macrophages) with initial focus in oncology. (NASDAQ: CARM)",
      url: "http://carismatx.com/",
      img: carismaLogo
    },
    {
      name: "Entrada is treating devastating diseases through the intracellular delivery of biologics. (NASDAQ: TRDA) ",
      url: "http://www.entradatx.com/",
      img: entradaLogo
    },
    {
      name: "EyeBio is a clinical stage company focused on epigenetic therapeutics for oncology and other serious diseases. (Acquired by Merck & Co., Inc., Rahway, NJ US).",
      url: "https://eyebiotech.com/",
      img: eyebioLogo
    },
    {
      name: "Imago is a clinical stage company focused on epigenetic therapeutics for oncology and other serious diseases. (NASDAQ: IMGO; Acquired by Merck & Co., Inc., Rahway, NJ US)",
      url: "http://www.imagobio.com/",
      img: imagoLogo
    },
    {
      name: "Formerly known as Adagio Therapeutics, Invivyd is developing antibodies that can broadly neutralize SARS-CoV-2, SARS-CoV-1 and potentially emergent coronaviruses. (NASDAQ: IVVD)",
      url: "https://invivyd.com/",
      img: InvivydLogo
    },
    {
      name: "Kymera is developing next-generation targeted protein degraders for oncology and inflammation. (NASDAQ: KYMR)",
      url: "http://www.kymeratx.com",
      img: kymeraLogo
    },
    {
      name: "Lava is developing proprietary bispecific gamma/delta T-cell engagers for oncology. (NASDAQ: LVTX)",
      url: "http://www.lavatherapeutics.com",
      img: lavaLogo
    },
    {
      name: "Spero is a multi-asset, clinical stage company focused on identifying, developing and commercializing novel treatments for multi-drug resistant bacterial infections. (NASDAQ: SPRO)",
      url: "https://sperotherapeutics.com/",
      img: speroLogo
    },
    {
      name: "Tidal is developing a gene therapy approach to engineering and reprogramming the immune system in situ. (Acquired by Sanofi)",
      url: "https://www.sanofi.com/en/media-room/press-releases/2021/2021-04-09-17-45-00-2207664",
      img: tidalLogo
    },
    {
      name: "Translate Bio develops RNA therapeutics using the body’s own machinery to produce proteins to treat diseases caused by the dysregulation of gene expression. (NASDAQ: TBIO; Acquired by Sanofi)",
      url: "https://www.sanofi.com/en/media-room/press-releases/2021/2021-08-03-05-00-00-2273307",
      img: translateLogo
    },
    {
      name: "Viridian is advancing new treatments for serious diseases using a patient-centric model of innovation. (NASDAQ: VRDN)",
      url: "https://www.viridiantherapeutics.com/",
      img: viridianLogo
    },
    {
      name: "Visterra is a clinical stage company focused on applying its novel Hierotope platform to identify unique epitopes and  rationally design and engineer precision biologics for serious diseases. (Acquired by Otsuka)",
      url: "http://www.visterrainc.com/",
      img: visterraLogo
    },
    {
      name: "Xilio Therapeutics is creating a new class of ultra-potent immunotherapies that are activated selectively within the tumor. (NASDAQ: XLO)",
      url: "https://www.xiliotx.com/",
      img: xilioLogo
    }
  ]
};

export default portfolio;
